const CategoriesGallery = require('@mshops-components-library/categories-gallery').default;
const { Discovery } = require('@mshops-components-library/discovery');
const CategoryGallery = require('@mshops-components-library/categories-gallery').default;

const DebugInfo = require('../../../appearance/DebugInfo');
const UnknownComponent = require('../../../appearance/UnknownComponent');

/**
 * NORMAL LIST OF COMPONENTS
 */
const Document = require('../../../appearance/Document');
const Layout = require('../../../appearance/Layout');
const Row = require('../../../appearance/Row');

const ErrorScreen = require('../../../appearance/ErrorScreen');
const NotFoundScreen = require('../../../appearance/NotFoundScreen');

const HelpComponent = require('../../../appearance/HelpComponent');

const BadgeCarousel = require('../../../appearance/BadgeCarousel');
const CarouselDesktop = require('../../../appearance/Carousel/desktop');
const CarouselMobile = require('../../../appearance/Carousel/mobile');
const CarouselWithTabsDesktop = require('../../../appearance/TabbedCarousel/desktop');
const CarouselWithTabsMobile = require('../../../appearance/TabbedCarousel/mobile');
const LabelImage = require('../../../appearance/LabelImage');
const Mosaic = require('../../../appearance/Mosaic');
const NewsLetterModal = require('../../../appearance/NewsLetterModal');
const PaymentMethodsDesktop = require('../../../appearance/PaymentMethods/desktop');
const PaymentMethodsMobile = require('../../../appearance/PaymentMethods/mobile');
const ShoppingInfoDesktop = require('../../../appearance/ShoppingInfo/desktop');
const ShoppingInfoMobile = require('../../../appearance/ShoppingInfo/mobile');
const ContentList = require('../../../appearance/ContentList');
const Slider = require('../../../appearance/Slider');

const Grid = require('../../../appearance/Grid');
const Card = require('../../../appearance/Card');
const SecondaryBanner = require('../../../appearance/SecondaryBanner');
const OffersBanner = require('../../../appearance/OffersBanner');

const ContactForm = require('../../../appearance/ContactForm');
const ContactInfo = require('../../../appearance/ContactInfo');

const CollectionGridDesktop = require('../../../appearance/CollectionGrid/desktop');
const CollectionGridMobile = require('../../../appearance/CollectionGrid/mobile');

const PriceAndImage = require('../../../appearance/PriceAndImage');

const HowBuyInfo = require('../../../appearance/HowBuyInfo');

const CancelBuyInfo = require('../../../appearance/CancelBuyInfo');

const BankConditionsInfo = require('../../../appearance/BankConditionsInfo');

const DataPrivacyInfo = require('../../../appearance/DataPrivacyInfo');
const LegalNoticesInfo = require('../../../appearance/LegalNoticesInfo');
const AdhesionContractInfo = require('../../../appearance/AdhesionContractInfo');
const BuyerInfo = require('../../../appearance/BuyerInfo');
const SectionText = require('../../../appearance/SectionText');
const CategoryLabelImage = require('../../../components/CategoryLabelImage');
const Video = require('../../../appearance/VideoAndText');
const CategoryCarousel = require('../../../appearance/CategoryCarousel');
const CollectionBoxes = require('../../../appearance/CollectionBoxes');
const CollectionBox = require('../../../components/CollectionBox');

/**
 * EXPORTS
 */
module.exports = {
  defaultComponent: UnknownComponent,
  /**
   * All the available components
   */
  nonEditables: {
    Document,
    Layout,
    Row,
    ErrorScreen,
    NotFoundScreen,
    HelpComponent,
    ContactForm,
    ContactInfo,
    CategoriesGallery,
    Slider,
    LabelImage,
    Carousel: BadgeCarousel,
    Discovery,
    Mosaic,
    NewsLetterModal,
    HowBuyInfo,
    CancelBuyInfo,
    BankConditionsInfo,
    DataPrivacyInfo,
    LegalNoticesInfo,
    AdhesionContractInfo,
    CategoryCarouselV2: CategoryCarousel,
    Card,
    PaymentMethods: {
      mobile: PaymentMethodsMobile,
      desktop: PaymentMethodsDesktop,
    },
    ShoppingInfo: {
      mobile: ShoppingInfoMobile,
      desktop: ShoppingInfoDesktop,
    },
    ContentList,
    BuyerInfo,
    Collection: {
      displayAs: {
        carousel: { mobile: CarouselMobile, desktop: CarouselDesktop },
        original: { mobile: CarouselMobile, desktop: CarouselDesktop },
        material: { mobile: CarouselMobile, desktop: CarouselDesktop },
        default: { mobile: CarouselMobile, desktop: CarouselDesktop },
      },
    },
    TabbedCarousel: {
      mobile: CarouselWithTabsMobile,
      desktop: CarouselWithTabsDesktop,
    },
    CollectionGrid: {
      displayAs: {
        original: {
          mobile: CollectionGridMobile,
          desktop: CollectionGridDesktop,
        },
        material: {
          mobile: CollectionGridMobile,
          desktop: CollectionGridDesktop,
        },
        default: {
          mobile: CollectionGridMobile,
          desktop: CollectionGridDesktop,
        },
      },
    },
    PriceAndImage,
    CategoryGallery,
    CategoryLabelImage,
    SectionText,
    Video,
    DebugInfo,
    CollectionBoxes,
    CollectionBox,
    Grid,
    BannerSplinter: SecondaryBanner,
    OffersBanner,
  },
  editables: {},
};
